<template>
    <div class="detail-page">
        <page-title :title="'案件详细信息'">
            <span>案件详细信息</span>
            <el-button type="info" size="mini" style="margin-left:20px;" @click="backPre">返  回</el-button>
        </page-title>
        <div class="detail-con">
            <div class="detail-case-info" v-if="caseinfo">
                <el-descriptions class="margin-top" title="案件基本信息" :column="3" :size="'medium'">
                    <el-descriptions-item label="案件编号">{{caseinfo.caseNum}}</el-descriptions-item>
                    <el-descriptions-item label="报案号">{{caseinfo.reportCaseNum}}</el-descriptions-item>
                    <el-descriptions-item label="报案时间">{{caseinfo.reportTime}}</el-descriptions-item>
                    <el-descriptions-item label="被保险人">{{caseinfo.insurePeo}}</el-descriptions-item>
                    <el-descriptions-item label="出险时间">{{caseinfo.outDangerTime}}</el-descriptions-item>
                    <el-descriptions-item label="出险单位">{{caseinfo.outDangerUnit}}</el-descriptions-item>
                    <el-descriptions-item label="出险地点">{{caseinfo.outDangerAddr}}</el-descriptions-item>
                </el-descriptions>
            </div>
            <div class="detail-repair-peo" v-if="repairPeoList&&repairPeoList.length!=0">
                <div style="padding-bottom:20px;font-weight:bold;">
                    <span>维修人员信息</span>
                </div>
                <div v-for="item in repairPeoList" :key="item.id">
                    <el-descriptions class="custom-des" title="维修员信息" :column="3" :size="'medium'">
                        <el-descriptions-item label="姓名">{{item.name}}</el-descriptions-item>
                        <el-descriptions-item label="联系方式">{{item.userTel}}</el-descriptions-item>
                        <el-descriptions-item label="证件号码">{{item.userUnit}}</el-descriptions-item>
                        <el-descriptions-item label="擅长领域" :column="1">{{item.userDesc}}</el-descriptions-item>
                    </el-descriptions>
                </div>
            </div>
            <div class="detail-repair-peo" v-if="repairList.length!=0">
                <div style="padding-bottom:20px;font-weight:bold;">
                    <span>案件维修情况</span>
                </div>
                <div v-for="item,index in repairList" :key="index">
                    <el-descriptions class="custom-des" :title="item.repairPeoName" :column="3" :size="'medium'">
                        <el-descriptions-item label="开始维修时间">{{item.repairTime}}</el-descriptions-item>
                        <el-descriptions-item label="维修完成时间">{{item.repairFinishTime}}</el-descriptions-item>
                        <el-descriptions-item label="事故原因">{{item.accidentReason}}</el-descriptions-item>
                        <el-descriptions-item label="维修情况说明">{{item.repairExplain}}</el-descriptions-item>
                    </el-descriptions>
                </div>
            </div>
            <div class="detail-cus-info" v-if="cusList&&cusList.length!=0">
                <div style="padding-bottom:20px;font-weight:bold;">
                    <span>关联客户信息</span>
                </div>
                <el-table :data="cusList" border>
                    <el-table-column prop="cusName" label="客户姓名" align="center"></el-table-column>
                    <el-table-column prop="cusTel" label="联系方式" align="center"></el-table-column>
                    <el-table-column prop="idnum" label="证件号码" align="center"></el-table-column>
                    <el-table-column label="电器信息" align="center">
                        <template slot-scope="scope">
                            <div v-if="scope.row.eleList&&scope.row.eleList.length!=0">
                                <el-tag v-for="item,index in scope.row.eleList" :key="index" :size="'mini'" style="margin-right: 10px;">{{item.eleName}}</el-tag>
                            </div>
                            <div v-else>
                                <span>暂未上传电器信息</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center" width="200">
                        <template slot-scope="scope">
                            <el-button type="text" @click="showDialog=true;showObj=scope.row;showTag=1;" style="padding: 2px 10px;">查看证件</el-button>
                            <el-button type="text" @click="showDialog=true;showList=scope.row.eleList;showTag=0;" style="padding: 2px 10px;">查看电器</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="detail-approve-info" v-if="checkList.length!=0">
                <div class="title">核定记录</div>
                <el-timeline>
                    <el-timeline-item
                    v-for="(activity, index) in checkList"
                    :key="index"
                    :timestamp="activity.approveTime"
                    placement="top">
                    <p>核定结果：{{activity.remark}}</p>
                    <p>核定意见：{{activity.opinion}}</p>                    
                    </el-timeline-item>
                </el-timeline>
            </div>
            <div class="detail-approve-info" v-if="reviewList.length!=0">
                <div class="title">复核记录</div>
                <el-timeline>
                    <el-timeline-item
                    v-for="(activity, index) in reviewList"
                    :key="index"
                    :timestamp="activity.approveTime"
                    placement="top">
                    <p>复核结果：{{activity.remark}}</p>
                    <p>复核意见：{{activity.opinion}}</p>                    
                    </el-timeline-item>
                </el-timeline>
            </div>
        </div>
        <div class="ops-btn">
            <div v-if="$store.state.user.userType=='02'&&src==='check'">
                <div style="font-size: 16px;font-weight: 700;margin-bottom: 10px;">核定意见</div>
                <div class="ops-input-con">
                    <el-input type="textarea" :rows="3" placeholder="请输入核定意见" v-model="checkOpinion"></el-input>
                </div>
                <div class="btn">
                    <el-button type="primary" size="small" @click="checkCase('ok')">核定通过</el-button>
                    <el-button type="warning" size="small" @click="checkCase('no')">核定不通过</el-button>
                </div>
            </div>
            <div v-if="$store.state.user.userType=='01'&&src==='review'">
                <div style="font-size: 16px;font-weight: 700;margin-bottom: 10px;">复核意见</div>
                <div class="ops-input-con">
                    <el-input type="textarea" :rows="3" placeholder="请输入复核意见" v-model="reviewOpinion"></el-input>
                </div>
                <div class="btn">
                    <el-button type="primary" size="small" @click="reviewCase('ok')">复核通过</el-button>
                    <el-button type="warning" size="small" @click="reviewCase('no')">复核不通过</el-button>
                </div>
            </div>
        </div>
        <el-dialog title="预览" :visible.sync="showDialog" custom-class="my-dialog-style" @close="dialogClose">
            <!-- 电器预览 -->
            <div v-if="showTag===0">
                <div style="font-weight: bold;padding-bottom: 10px;">客户电器信息</div>
                <div v-if="showList.length!=0">
                    <el-tabs value="0" type="card">
                        <el-tab-pane :label="item.eleName" :name="index+''" v-for="item,index in showList" :key="index">
                            <el-image :src="imgurl+item1.tempFilePath" style="height:360px;margin-right: 20px;" v-for="item1,index1 in item.imgObj" :key="index1"></el-image>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div v-else style="display: flex;flex-direction: column;align-items: center;">
                    <el-image :src="require('../../assets/icon/noData.png')"></el-image>
                    <span>暂未上传电器信息</span>
                </div>
            </div>
            <!-- 证件预览 -->
            <div v-else-if="showTag===1">
                <div style="font-weight: bold;padding-bottom: 10px;">客户证件信息</div>
                <div v-if="showObj.idImgFront&&imgurl+showObj.idImgBack">
                    <el-image :src="imgurl+showObj.idImgFront" style="width:420px;height:240px;margin-right: 20px;"></el-image>
                    <el-image :src="imgurl+showObj.idImgBack" style="width:420px;height:240px;"></el-image> 
                </div>
                <div v-else style="display: flex;flex-direction: column;align-items: center;">
                    <el-image :src="require('../../assets/icon/noData.png')"></el-image>
                    <span>暂未上传客户证件信息</span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "Detail",
    data() {
        return {
            id:"",
            caseinfo:null,//案件基本信息
            cusList:null,//客户基本信息
            repairPeoList:[],//维修工信息...？需关联获取相关信息
            repairList:[],//维修情况说明
            checkList:[],
            reviewList:[],
            src:"",//操作来源：detail、check、review
            checkOpinion:"",
            reviewOpinion:"",
            showDialog:false,//图片预览界面
            showTag:0,//0：电器预览；1：证件预览
            showList:[],//待预览的电器数据列表
            showObj:null,//带预览的客户证件对象
        }
    },
    created(){
        const that = this;
        that.src = that.$route.query.flag;
        if(that.$route.query.id){
            that.id = that.$route.query.id;
            that.initPage();
        }
    },
    methods: {
        /**初始化页面 */
        initPage(){
            const that =this;
            that.request.get("case/detail",{id:that.id}).then(res=>{
                console.log(res);
                if(res.code ===200){
                    that.caseinfo = res.data.caseInfo;
                    that.cusList = res.data.cusList;
                    that.repairPeoList = res.data.repairPeoList;
                    that.repairList = res.data.repairList;
                    that.checkList = res.data.checkList;
                    that.reviewList = res.data.reviewList;
                }
            });
        },
        /**返回上一页 */
        backPre() {
            this.$router.go(-1)
        },
        /**核定操作 */
        checkCase(e){
            const that =this;
            let params = {
                caseId:that.id,
                checkOpinion:that.checkOpinion,
                isPass:e
            }
            that.request.post("case/check",params).then(res=>{
                if(res.code === 200){
                    this.$router.push("/case/index");
                }
            });
        },
        /**复核操作*/
        reviewCase(e){
            const that =this;
            let params = {
                caseId:that.id,
                reviewOpinion:that.reviewOpinion,
                isPass:e
            }
            that.request.post("case/review",params).then(res=>{
                if(res.code === 200){
                    this.$router.push("/case/index");
                }
            });
        },
        /**客户证件电器预览 */
        seeCard(e){
            console.log(e);
            // let _showObj = {
            //     eleName:"正面",

            // }
        },
        /**图片预览窗口关闭事件 */
        dialogClose(){

        }
    }
}
</script>
<style lang="less" scoped>
.detail-page {
    .ele-img{
        display: flex;
        flex-direction: row;
        padding: 10px 0px;
    }
    .ops-btn{
        .btn{
            margin-top: 10px;
        }
    }
    .detail-approve-info{
        margin-top: 20px;
        .title{
            padding: 10px 0px 20px; 
            font-weight: bold;
        }
    }

    /deep/ .custom-des{
        margin-left: 20px;
        .el-descriptions__header{
            margin-bottom: 5px;
        }
    }
}
</style>